import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleRight';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleDown';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faInfo } from '@fortawesome/free-solid-svg-icons/faInfo';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons/faMinusCircle';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle';
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt';
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit';
import { faCopy} from '@fortawesome/free-solid-svg-icons/faCopy';
import { library, dom } from '@fortawesome/fontawesome-svg-core';

library.add(
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faArrowAltCircleDown,
  faCalendarAlt,
  faCheck,
  faCopy,
  faDownload,
  faEnvelope,
  faEye,
  faHome,
  faInfo,
  faMinus,
  faMinusCircle,
  faPencilAlt,
  faPlus,
  faPlusCircle,
  faStar,
  faTimes,
  faTrashAlt,
  faUnlock,
  faUserEdit,
);

dom.watch();
